import React from "react";
import { Link } from "gatsby";

const CampaignFooter = ({ salesforce, tableau }) => {
    return (
        <section
            className="campaign-footer"
            style={salesforce ? { background: "#ffffff" } : { background: "#ffffff" }}
        >
            <div className="container">
                {tableau && (
                    <div className="gap-05x">
                        <Link
                            to="/privacy-policy"
                            className="text-p6  text-clr-secondary text-decor-none text-fw-regular"
                        >
                            Privacy policy
                        </Link>
                    </div>
                )}
                <p className="text-p6 text-clr-secondary text-fw-regular">
                    © 2023 Sedin Technologies Inc
                </p>
            </div>
        </section>
    );
};

export default CampaignFooter;
