import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const ClientsGrid = ({ data }) => {
    return (
        <div className="clients-grid">
            <p className="text-p3 text-clr-secondary text-fw-medium">Trusted by</p>
            <div className="clients-grid__logo">
                {data.map((el, i) => (
                    <GatsbyImage key={i} image={el.gatsbyImageData} alt={el.title} />
                ))}
            </div>
        </div>
    );
};

export default ClientsGrid;
