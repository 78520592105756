import React from "react";
import UpArrow from "../images/uparrow.svg";
import DownArrow from "../images/downarrow.svg";
import { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
const DashboardFaq = ({ data, image }) => {
    const [selected, setSelected] = useState(null);
    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null);
        }
        setSelected(i);
    };
    return (
        <div className="dashboard-faq">
            <div className="dashboard-faq__grid">
                <div className="dashboard-faq__grid-item">
                    {data.map((node, i) => {
                        return (
                            <div className="accordian  gap-05x" key={i} onClick={(e) => toggle(i)}>
                                <div className="accordian-header flex flex-jc-sb flex-ai-c ">
                                    <h4 className="sedin-faq__grid__title  text-p3 text-fw-medium text-primary-clr">
                                        {node.title}
                                    </h4>
                                    {selected === i ? (
                                        <img src={UpArrow} alt="upArrow" />
                                    ) : (
                                        <img src={DownArrow} alt="downArrow" />
                                    )}
                                </div>

                                <div
                                    className={
                                        selected === i
                                            ? "sedin-faq__grid__description text-clr-secondary text-p4 text-clr-secondary show"
                                            : "sedin-faq__grid__description text-clr-secondary text-p4 text-clr-secondary"
                                    }
                                >
                                    <p>{node.description.description}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="dashboard-faq__grid-item">
                    <GatsbyImage image={image.gatsbyImageData} alt={image.title} />
                </div>
            </div>
        </div>
    );
};

export default DashboardFaq;
