import React from "react";
import HeaderV2 from "../components/HeaderV2";
import { graphql } from "gatsby";
import FormBanner from "../components/FormBanner";
import ClientsGrid from "../components/ClientsGrid";
import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import ServiceFacilities from "../components/ServiceFacilities";
import CampaignFooter from "../components/CampaignFooter";
import { Link } from "gatsby";
import DashboardFaq from "../components/DashboardFaq";
import TableauBannerForm from "../components/TableauBannerForm";
import Testimonials from "../components/Testimonials";
import "../styles/main.scss";

const TableauDeveloper = ({ data }) => {
    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <p className="text-p3 gap-1x">{children}</p>,
            [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
            [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
        },
    };
    const bgImage =
        data.contentfulCampaignTableauDeveloper.banner?.gatsbyImageData?.images.fallback.src;
    return (
        <>
            <section className="tableau-developer-banner" id="tableau-developer-banner">
                <div
                    style={{
                        background: `url(${bgImage})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                    }}
                >
                    <div className="container">
                        <HeaderV2 />
                        <TableauBannerForm
                            title={data?.contentfulCampaignTableauDeveloper?.title}
                            description={data?.contentfulCampaignTableauDeveloper?.description}
                            logo={data?.contentfulCampaignTableauDeveloper?.logo}
                            formTitle={data?.contentfulCampaignTableauDeveloper?.formHeading}
                        />
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <ClientsGrid data={data.contentfulCampaignTableauDeveloper.clients} />
                </div>
            </section>

            <section className="tableau-developer-dashboard">
                <div className="container container--mid">
                    <div className="tableau-developer-dashboard__image">
                        <GatsbyImage
                            image={
                                data.contentfulCampaignTableauDeveloper.dashboardImage
                                    .gatsbyImageData
                            }
                            alt={data.contentfulCampaignTableauDeveloper.dashboardImage.title}
                            loading="lazy"
                        />
                    </div>
                    <div className="tableau-developer-dashboard__grid">
                        <h2 className="text-h2 text-fw-medium text-clr-primary">
                            {data.contentfulCampaignTableauDeveloper.dashboardHeading}
                        </h2>
                        <div className="text-p3 text-clr-primary">
                            {renderRichText(
                                data.contentfulCampaignTableauDeveloper.dashboardPoints
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <section className="dashboard-development">
                <div className="container container--mid-v2">
                    <h2 className="text-h2 text-fw-medium dashboard-development__text text-clr-primary">
                        {data?.contentfulCampaignTableauDeveloper?.visualizationHeader}
                    </h2>
                    <div className="dashboard-development__grid">
                        {data?.contentfulCampaignTableauDeveloper?.visualization.map((v) => (
                            <>
                                <div className="dashboard-development__grid-item">
                                    {/* <GatsbyImage
                                        image={v.image.gatsbyImageData}
                                        alt={v.image.title}
                                        className="gap-1x img-w-100"
                                        loading="lazy"
                                    /> */}
                                    <p className="text-p3 gap-05x text-fw-medium">{v.title}</p>
                                    <div className="text-p4 text-clr-secondary">
                                        {v.description.description}
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </section>
            <section className="dashboard-faq">
                <div className="container container--mid-v2">
                    <h2 className="text-h2  text-fw-medium">
                        {data.contentfulCampaignTableauDeveloper.faqheader}
                    </h2>
                    <DashboardFaq
                        data={data.contentfulCampaignTableauDeveloper.faq}
                        image={data.contentfulCampaignTableauDeveloper.faqImage}
                    />
                </div>
            </section>

            <section>
                <div className="container container--mid-v2">
                    <Testimonials
                        testimonialData={data.contentfulCampaignTableauDeveloper.testimonial}
                    />
                </div>
            </section>

            <section className="service-process">
                <div className="container container--mid-v2">
                    <ServiceFacilities
                        facilities={data.contentfulCampaignTableauDeveloper.serviceProcess}
                        title="Our process"
                        process
                    />
                </div>
            </section>
            <section className="global-campaign-contact" id="campaign-contact">
                <div className="container ">
                    <div className="global-campaign-contact__text">
                        <h2 className="text-h2  text-fw-medium">
                            {data.contentfulCampaignTableauDeveloper.footerCallToAction.header}
                        </h2>
                        {/* <EmailBlockV2
                            FooterCallToAction
                            background="#f8f9fa"
                            title="Talk to our experts"
                        /> */}
                        <a href="#tableau-developer-banner" className="sdn-cta-btn">
                            {data.contentfulCampaignTableauDeveloper.footerCallToAction.ctaText}
                        </a>
                    </div>
                </div>
            </section>

            <CampaignFooter tableau />
        </>
    );
};

export const query = graphql`
    query TableauCampaign($id: String) {
        contentfulCampaignTableauDeveloper(id: { eq: $id }) {
            title
            logo {
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                title
            }
            clients {
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)

                title
            }
            formHeading {
                raw
            }
            footerCallToAction {
                ctaText
                header
            }
            description
            dashboardHeading
            banner {
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
            }
            dashboardPoints {
                raw
            }
            dashboardImage {
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                title
            }
            serviceProcess {
                title
                processDescription {
                    processDescription
                }
                icon {
                    title
                    gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                }
            }
            faqheader
            faq {
                title
                description {
                    description
                }
            }
            faqImage {
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                title
            }
            visualizationHeader
            visualization {
                title
                description {
                    description
                }
                image {
                    gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                    title
                }
            }
            testimonial {
                id
                personDesignation
                personName
                profileImage {
                    gatsbyImageData(
                        quality: 100
                        sizes: "250"
                        layout: CONSTRAINED
                        placeholder: BLURRED
                    )
                    title
                }
                testimonialType
                testimonialStatement {
                    testimonialStatement
                }
            }
        }
    }
`;

export default TableauDeveloper;
