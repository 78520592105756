import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const ServiceFacilities = ({ facilities, title, salesforce, process }) => {
    return (
        <div className="facilites">
            <h2 className="facilities__text text-h2 text-clr-primary text-fw-medium salesforce-heading">
                {title}
            </h2>
            <div className="facilities__grid">
                {facilities?.map((item, i) => (
                    <div className="facilities__grid-item" key={i}>
                        {item.icon.gatsbyImageData ? (
                            <GatsbyImage
                                class="facility-icon"
                                image={item.icon.gatsbyImageData}
                                alt={item.title}
                            />
                        ) : (
                            <img
                                className="facility-icon"
                                src={salesforce ? item.icon : item.icon.file.url}
                                alt={item.title}
                            />
                        )}
                        <p className="text-p3  title  text-clr-primary text-fw-medium">
                            {item.title}
                        </p>
                        {item.processDescription && process ? (
                            <p className="text-p3 text-clr-secondary text-fw-regular">
                                {item.processDescription.processDescription}
                            </p>
                        ) : (
                            <div>
                                <p className="text-p3 text-clr-secondary text-fw-regular gap-01x">
                                    {item.description}
                                </p>
                                <div className="text-p4">
                                    <span
                                        style={{
                                            marginTop: "10px",
                                            paddingTop: "50px",
                                        }}
                                    >
                                        {item.items?.map((allitems, indx) => (
                                            <span
                                                className="facilities__grid-item__blocks"
                                                key={indx}
                                            >
                                                {allitems}
                                            </span>
                                        ))}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ServiceFacilities;
